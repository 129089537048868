<template>
  <div class="user-licenses">
	<div id="messages"></div>
	<b-card title="User Info" label-cols-lg="2">
		<b-card-text>Email - {{ userCurrentLicense.user.email }}</b-card-text>
		<b-card-text>First name - {{ userCurrentLicense.user.first_name }}</b-card-text>
		<b-card-text>Last Name - {{ userCurrentLicense.user.last_name }}</b-card-text>
		<b-card-text>Created date - {{ userCurrentLicense.user.date_created }}</b-card-text>
		<b-form-group label="Has unlimited licenses" label-cols-lg="2" class="checkbox">
		  <b-form-checkbox v-model="userCurrentLicense.user.has_unlimited_licenses">
		  </b-form-checkbox>
		</b-form-group>
		<b-form-group label="Payment frequency" label-cols-lg="2">
		  <b-form-select
			lg="2"
			v-model="userCurrentLicense.payment_frequency"
			:options='paymentFrequencyOptions'
			style="width: 10%"
		  />
		</b-form-group>
		<b-form-group label="Paid date" label-cols-lg="2">
			<b-form-datepicker
				v-model="userCurrentLicense.paid_date"
				style="width: 20%"
			/>
		</b-form-group>
		<b-form-group label="Expiration date" label-cols-lg="2">
			<b-form-datepicker
				v-model="userCurrentLicense.expired_date"
				style="width: 20%"
				:readonly="true"
				:state="!this.userCurrentLicense.is_expired"
			/>
		</b-form-group>
	</b-card>
	<div v-if="userCurrentLicense.id" class="current-license">
	  <ul class="current-license-list">
		<li class="license-item">
		  <span>Basic</span>
		  <div class="license-count-wrapper">
			<b-form-input
			  v-model="userCurrentLicense.basic_count"
			  type="number"
			  min="0"
			  class="license-count"
			>
			</b-form-input>
		  </div>
		</li>
		<li class="license-item">
		  <span>Plus</span>
		  <div class="license-count-wrapper">
			<b-form-input
			  v-model="userCurrentLicense.plus_count"
			  type="number"
			  min="0"
			  class="license-count"
			></b-form-input>
		  </div>
		</li>
		<li class="license-item">
		  <span>Pro</span>
		  <div class="license-count-wrapper">
			<b-form-input
			  v-model="userCurrentLicense.pro_count"
			  type="number"
			  min="0"
			  class="license-count"
			></b-form-input>
		  </div>
		</li>
	  </ul>
	  <b-button class="mt-2" variant="primary" id="make-payment" @click="submit">
		Submit
	  </b-button>
	</div>
	<div v-else class="no-license">
	  <h2>Loading...</h2>
	</div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  data() {
	this.publishableKey = process.env.VUE_APP_STRIPE_API_KEY;
	return {
	  source: null,
	  elementsOptions: {
		appearance: {}, // appearance options
	  },
	  confirmParams: {
		return_url: "/success", // success url
	  },
	  paymentFrequencyOptions: {1: 'Monthly', 2: 'Annually'}
	};
  },
  computed: {
	userCurrentLicense() {
	  return this.$store.getters['License/getLicense']
	},
	isExpired() {
		console.log("this.userCurrentLicense.is_expired", this.userCurrentLicense.is_expired)
	  return this.userCurrentLicense.is_expired === 'true' ? true : false
	},
  },
  methods: {
	submit() {
		this.$store.dispatch('License/PATCH_USER_LICENSE', this.userCurrentLicense)
			.then(() => {
				this.$toast({
				component: ToastificationContent,
				position: "top-right",
				props: {
				title:
					"You have successfully update User license",
				icon: "EditIcon",
				variant: "success",
				},
			});
			})
	}
  },
};
</script>

<style lang="scss" scoped>
.current-license {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: center;
  .current-license-list {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 100px;
	width: 30%;
	font-size: calc(0.5 * 1vw + 1rem);
	padding-left: 0;
	.license-item {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  .license-count-wrapper {
		display: flex;
		align-items: center;
		.license-count {
		  max-width: 120px;
		  font-size: calc(0.5 * 1vw + 1rem);
		}
		.license-count-difference {
		  position: absolute;
		  margin-left: 65px;
		  margin-bottom: 2px;
		  color: rgb(40, 151, 18);
		  &.less {
			color: rgb(230, 42, 42) !important;
		  }
		}
	  }
	}
  }
}

@media screen and (max-width: 350px) {
  .less-difference {
	margin-left: -90px !important;
	margin-top: 60px;
  }
}
</style>
